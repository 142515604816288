import { useRouter } from 'next/router';
import { getCountdownCopy } from '@constants';
import { ContentfulRichTextRenderer, Countdown, ElevatorCarousel, Flex, Img, Paragraph, SEO, Title } from '@components';
import { ContentfulCollectionTeaser, NormalizedContentfulPageBlocks } from '@ts/contentful';
import styles from './CollectionTeaserPage.module.scss';

const CollectionTeaserPage = ({
	collection,
	title,
	seoMetadata,
}: { collection: ContentfulCollectionTeaser } & Pick<NormalizedContentfulPageBlocks, 'seoMetadata' | 'title'>) => {
	const {
		carouselSlides,
		collectionLogo,
		description,
		heroImage,
		heroLabel,
		legalCopy,
		launchDate,
		launchDateDescription,
		legalFooter,
		staticHeader,
		carouselSeparator,
		spreadLegalCopy,
	} = collection as ContentfulCollectionTeaser;
	const { locale } = useRouter();
	const COUNTDOWN_COPY = getCountdownCopy(locale);

	return (
		<>
			<SEO
				title={seoMetadata?.seoTitle ?? title}
				noindex={seoMetadata?.no_index}
				nofollow={seoMetadata?.no_follow}
				{...(!!seoMetadata?.description ? { description: seoMetadata?.description } : {})}
			/>
			<ElevatorCarousel
				slides={carouselSlides}
				legalCopy={legalCopy ?? ''}
				staticHeader={staticHeader}
				useCarouselSeparator={carouselSeparator}
			/>
			<div className={styles['landing-container']}>
				<Flex fullWidth justify='center' className={styles['landing-top-display']}>
					<Img
						className={styles['collection-title-image']}
						src={collectionLogo.url}
						alt='main collection banner'
						width={350}
						height={97}
						noSrcset={true}
					/>{' '}
					<Flex column align='start' justify='center' className={styles['launch-banner']}>
						{launchDateDescription && (
							<Title style={{ marginRight: '0.8rem', marginBottom: '0.8rem' }}>{launchDateDescription}</Title>
						)}
						{launchDate && (
							<div className={styles['launch-countdown']}>
								<Countdown date={launchDate} text={COUNTDOWN_COPY.LAUNCH} />
							</div>
						)}
					</Flex>
				</Flex>
				<div className={styles['landing-description']}>
					<Paragraph>{description}</Paragraph>
				</div>
				<Flex justify='center' className={styles['landing-main-image']}>
					<div className={styles['image-container']}>
						<Img
							className={styles['hero-image']}
							src={heroImage.url}
							alt='main collection banner'
							width={heroImage.width}
							height={heroImage.height}
							noSrcset={true}
						/>
						{spreadLegalCopy && (
							<div className={styles['image-legal-copy']}>
								<Paragraph>{legalCopy}</Paragraph>
							</div>
						)}
					</div>
					{heroLabel && (
						<div className={styles['rich-text-container']}>
							<div className={styles['rich-text']}>{ContentfulRichTextRenderer(false, { text: heroLabel })}</div>
							{spreadLegalCopy && <Paragraph>{legalCopy}</Paragraph>}
						</div>
					)}
				</Flex>
				{legalFooter && <Paragraph className={styles['legal-footer']}>{legalFooter}</Paragraph>}
			</div>
		</>
	);
};

export default CollectionTeaserPage;
